define("ember-holy-futuristic-template-namespacing-batman/helpers/-translate-dynamic-2", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function ([name]) {
    if (typeof name !== 'string') {
      return name;
    }
    if (name.indexOf('::') > -1) {
      (true && !(false) && (0, _debug.deprecate)('ember-holy-futuristic-template-namespacing-batman: Using `::` for namespacing is deprecated, please migrate from {{component "' + name + '" to {{"' + name.replace('::', '$') + '"', false, {
        id: 'ember-holy-futuristic-template-namespacing-batman.colon-syntax',
        until: '0.2.0'
      }));
      return name.replace('::', '@');
    } else {
      return name.replace('$', '@');
    }
  });
});