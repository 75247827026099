define("ember-world-flags/helpers/world-flag", ["exports", "@ember/template", "@ember/component/helper"], function (_exports, _template, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultSize = 16;
  var defaultCountry = 'us';
  var _default = _exports.default = (0, _helper.helper)(function worldFlag(params) {
    var length, country, size;
    length = params.length;
    country = params[0] || defaultCountry;
    size = params[1] || defaultSize;
    if (length === 0 || length > 2) {
      throw new TypeError('Invalid Number of arguments, expected at least 1 and at most 2');
    }
    var result = `<i class="flag${size} flag-${country.toLowerCase()}"></i>`;
    return (0, _template.htmlSafe)(result);
  });
});