define('@fortawesome/pro-duotone-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faSortDown = {
    prefix: 'fad',
    iconName: 'sort-down',
    icon: [320, 512, ["sort-desc"], "f0dd", ["M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zM109.3 160H210.7L160 109.3 109.3 160z", "M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"]]
  };
  var faSquareCheck = {
    prefix: 'fad',
    iconName: 'square-check',
    icon: [448, 512, [9745, 9989, 61510, "check-square"], "f14a", ["M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z", "M337 175c9.4 9.4 9.4 24.6 0 33.9L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0z"]]
  };
  var faChevronUp = {
    prefix: 'fad',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", ["M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z", ""]]
  };
  var faAngleRight = {
    prefix: 'fad',
    iconName: 'angle-right',
    icon: [320, 512, [8250], "f105", ["M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z", ""]]
  };
  var faUser = {
    prefix: 'fad',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", ["M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128z", "M0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]]
  };
  var faPaperPlaneTop = {
    prefix: 'fad',
    iconName: 'paper-plane-top',
    icon: [512, 512, ["paper-plane-alt", "send"], "e20a", ["M-1.2 83.5c-18-33.4 16.3-70.8 51.1-55.7L491.8 219.3c32.1 13.9 32.1 59.5 0 73.4L49.9 484.2c-34.8 15.1-69.1-22.3-51.1-55.7L75.2 286.6 266 262.7c3.4-.4 6-3.3 6-6.7s-2.6-6.3-6-6.7L75.2 225.4-1.2 83.5z", "M75.2 286.6L266 262.7c3.4-.4 6-3.3 6-6.7s-2.6-6.3-6-6.7L75.2 225.4l8.3 15.4c5.1 9.5 5.1 20.9 0 30.3l-8.3 15.4z"]]
  };
  var faCircleArrowLeft = {
    prefix: 'fad',
    iconName: 'circle-arrow-left',
    icon: [512, 512, ["arrow-circle-left"], "f0a8", ["M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM231 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L376 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-182.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L119 273c-9.4-9.4-9.4-24.6 0-33.9L231 127z", "M119 273c-9.4-9.4-9.4-24.6 0-33.9L231 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L376 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-182.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L119 273z"]]
  };
  var faSquare = {
    prefix: 'fad',
    iconName: 'square',
    icon: [448, 512, [9632, 9723, 9724, 61590], "f0c8", ["M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z", ""]]
  };
  var faCircleArrowRight = {
    prefix: 'fad',
    iconName: 'circle-arrow-right',
    icon: [512, 512, ["arrow-circle-right"], "f0a9", ["M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z", "M393 239c9.4 9.4 9.4 24.6 0 33.9L281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239z"]]
  };
  var faSquareEllipsis = {
    prefix: 'fad',
    iconName: 'square-ellipsis',
    icon: [448, 512, [], "e26e", ["M0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32C28.7 32 0 60.7 0 96L0 416zM192 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm-64 32a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm160-32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z", "M128 288a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm96 0a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm64-32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]]
  };
  var faSortUp = {
    prefix: 'fad',
    iconName: 'sort-up',
    icon: [320, 512, ["sort-asc"], "f0de", ["M137.4 470.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H32c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128zM109.3 352H210.7L160 402.7 109.3 352z", "M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]]
  };
  var faSpinnerThird = {
    prefix: 'fad',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", ["M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z", "M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"]]
  };
  var faClock = {
    prefix: 'fad',
    iconName: 'clock',
    icon: [512, 512, [128339, "clock-four"], "f017", ["M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z", "M256 96c-13.3 0-24 10.7-24 24V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24z"]]
  };
  var faMagnifyingGlass = {
    prefix: 'fad',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", ["M208 64a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 352A208 208 0 1 0 208 0a208 208 0 1 0 0 416z", "M330.7 376L457.4 502.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L376 330.7C363.3 348 348 363.3 330.7 376z"]]
  };
  var faSearch = faMagnifyingGlass;
  var faChevronDown = {
    prefix: 'fad',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", ["M278.6 406.6c-12.5 12.5-32.8 12.5-45.3 0l-192-192c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L256 338.7 425.4 169.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-192 192z", ""]]
  };
  var faXmark = {
    prefix: 'fad',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", ["M297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6z", ""]]
  };
  var faTimes = faXmark;
  var faFilterSlash = {
    prefix: 'fad',
    iconName: 'filter-slash',
    icon: [640, 512, [], "e17d", ["M202.5 255.5L384 398.5V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9l-53.5-65.4zM567 97.3l-160.4 196L81.7 38.8C88.2 34.4 95.9 32 104 32H536c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5z", "M5.1 9.2C13.3-1.2 28.4-3.1 38.8 5.1l592 464c10.4 8.2 12.3 23.3 4.1 33.7s-23.3 12.3-33.7 4.1L9.2 42.9C-1.2 34.7-3.1 19.6 5.1 9.2z"]]
  };
  var faHammer = {
    prefix: 'fad',
    iconName: 'hammer',
    icon: [576, 512, [128296], "f6e3", ["M285.5 162.1L27.4 377.1C10.1 391.6 0 413.1 0 435.7C0 477.8 34.1 512 76.3 512c22.6 0 44.1-10.1 58.6-27.4L350.7 225.6c-6.1-3.7-11.9-8.2-17.2-13.5l-38.1-38.1c-3.7-3.7-6.9-7.7-9.8-11.9z", "M333.5 212.1c21.8 21.8 51.9 30.2 80 25.4L431 255l-8 8c-9.4 9.4-9.4 24.6 0 33.9l24 24c9.4 9.4 24.6 9.4 33.9 0l88-88c9.4-9.4 9.4-24.6 0-33.9l-24-24c-9.4-9.4-24.6-9.4-33.9 0l-8 8-15.9-15.9c6.6-30.3-2.4-63.2-26.6-86l-44.6-42C389.2 14 353.9 0 317.2 0H299.2C279 0 259.1 4.2 240.7 12.4l-47.2 21c-5.6 2.5-9.2 7.9-9.5 14s3 11.8 8.3 14.7L272 105.5v12.1c0 21.1 8.4 41.4 23.3 56.3l38.1 38.1z"]]
  };
  var faSquareXmark = {
    prefix: 'fad',
    iconName: 'square-xmark',
    icon: [448, 512, [10062, "times-square", "xmark-square"], "f2d3", ["M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm79 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z", "M177 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47z"]]
  };
  var faCheck = {
    prefix: 'fad',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", ["", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]]
  };

  exports.faAngleRight = faAngleRight;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronUp = faChevronUp;
  exports.faCircleArrowLeft = faCircleArrowLeft;
  exports.faCircleArrowRight = faCircleArrowRight;
  exports.faClock = faClock;
  exports.faFilterSlash = faFilterSlash;
  exports.faHammer = faHammer;
  exports.faPaperPlaneTop = faPaperPlaneTop;
  exports.faSearch = faSearch;
  exports.faSortDown = faSortDown;
  exports.faSortUp = faSortUp;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faSquare = faSquare;
  exports.faSquareCheck = faSquareCheck;
  exports.faSquareEllipsis = faSquareEllipsis;
  exports.faSquareXmark = faSquareXmark;
  exports.faTimes = faTimes;
  exports.faUser = faUser;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
