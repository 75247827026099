define("ember-popup-component/utils/click-outside-el", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkEventTargetIsOutsideContent;
  function checkEventTargetIsOutsideContent(el, contentEl) {
    if (el === contentEl) return false;
    if (el.parentElement) return checkEventTargetIsOutsideContent(el.parentElement, contentEl);
    return true;
  }
});