define('@fortawesome/pro-thin-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faSortDown = {
    prefix: 'fat',
    iconName: 'sort-down',
    icon: [320, 512, ["sort-desc"], "f0dd", "M144.6 457.4c4 4.2 9.6 6.6 15.4 6.6s11.4-2.4 15.4-6.6L300.6 325.3c2.2-2.3 3.5-5.4 3.5-8.7c0-7-5.6-12.6-12.6-12.6H28.6c-7 0-12.6 5.6-12.6 12.6c0 3.2 1.2 6.3 3.5 8.7L144.6 457.4zm-11.6 11L7.8 336.3C2.8 331 0 323.9 0 316.6C0 300.8 12.8 288 28.6 288H291.4c15.8 0 28.6 12.8 28.6 28.6c0 7.3-2.8 14.4-7.8 19.7L187 468.4c-7 7.4-16.8 11.6-27 11.6s-20-4.2-27-11.6z"]
  };
  var faSquareCheck = {
    prefix: 'fat',
    iconName: 'square-check',
    icon: [448, 512, [9745, 9989, 61510, "check-square"], "f14a", "M64 48C37.5 48 16 69.5 16 96V416c0 26.5 21.5 48 48 48H384c26.5 0 48-21.5 48-48V96c0-26.5-21.5-48-48-48H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM325.7 197.7l-128 128c-3.1 3.1-8.2 3.1-11.3 0l-64-64c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0L192 308.7 314.3 186.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3z"]
  };
  var faChevronUp = {
    prefix: 'fat',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", "M250.3 114.3c3.1-3.1 8.2-3.1 11.3 0l216 216c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L256 131.3 45.7 341.7c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l216-216z"]
  };
  var faAngleRight = {
    prefix: 'fat',
    iconName: 'angle-right',
    icon: [320, 512, [8250], "f105", "M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"]
  };
  var faUser = {
    prefix: 'fat',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M336 128a112 112 0 1 0 -224 0 112 112 0 1 0 224 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM16 482.3c0 7.6 6.1 13.7 13.7 13.7H418.3c7.6 0 13.7-6.1 13.7-13.7C432 392.7 359.3 320 269.7 320H178.3C88.7 320 16 392.7 16 482.3zm-16 0C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faPaperPlaneTop = {
    prefix: 'fat',
    iconName: 'paper-plane-top',
    icon: [512, 512, ["paper-plane-alt", "send"], "e20a", "M20.4 53c-4.6 4.9-5.7 12.1-2.7 18.1L106.1 248H493.9c-1.7-2.9-4.3-5.3-7.6-6.7l-448-192C32.1 46.6 25 48.1 20.4 53zm85.8 211L17.7 440.8c-3 6-1.9 13.2 2.7 18.1s11.8 6.4 17.9 3.7l6.3 14.7c-12.3 5.3-26.7 2.3-35.9-7.5s-11.3-24.3-5.3-36.3L92.2 256 3.4 78.3c-6-12-3.9-26.5 5.3-36.3s23.5-12.7 35.9-7.5l448 192c11.8 5 19.4 16.6 19.4 29.4s-7.6 24.4-19.4 29.4l-448 192-6.3-14.7 448-192c3.2-1.4 5.9-3.8 7.6-6.7H106.1z"]
  };
  var faCircleArrowLeft = {
    prefix: 'fat',
    iconName: 'circle-arrow-left',
    icon: [512, 512, ["arrow-circle-left"], "f0a8", "M16 256a240 240 0 1 1 480 0A240 240 0 1 1 16 256zm496 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM234.3 146.3l-104 104c-3.1 3.1-3.1 8.2 0 11.3l104 104c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L155.3 264 376 264c4.4 0 8-3.6 8-8s-3.6-8-8-8l-220.7 0 90.3-90.3c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0z"]
  };
  var faSquare = {
    prefix: 'fat',
    iconName: 'square',
    icon: [448, 512, [9632, 9723, 9724, 61590], "f0c8", "M384 48c26.5 0 48 21.5 48 48V416c0 26.5-21.5 48-48 48H64c-26.5 0-48-21.5-48-48V96c0-26.5 21.5-48 48-48H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
  };
  var faCircleArrowRight = {
    prefix: 'fat',
    iconName: 'circle-arrow-right',
    icon: [512, 512, ["arrow-circle-right"], "f0a9", "M496 256A240 240 0 1 1 16 256a240 240 0 1 1 480 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM277.7 365.7l104-104c3.1-3.1 3.1-8.2 0-11.3l-104-104c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L356.7 248 136 248c-4.4 0-8 3.6-8 8s3.6 8 8 8l220.7 0-90.3 90.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0z"]
  };
  var faSquareEllipsis = {
    prefix: 'fat',
    iconName: 'square-ellipsis',
    icon: [448, 512, [], "e26e", "M16 416c0 26.5 21.5 48 48 48l320 0c26.5 0 48-21.5 48-48l0-320c0-26.5-21.5-48-48-48L64 48C37.5 48 16 69.5 16 96l0 320zm48 64c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480zM208 256a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zm-80 16a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm176-16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  var faSortUp = {
    prefix: 'fat',
    iconName: 'sort-up',
    icon: [320, 512, ["sort-asc"], "f0de", "M144.6 54.6c4-4.2 9.6-6.6 15.4-6.6s11.4 2.4 15.4 6.6L300.5 186.7c2.2 2.3 3.5 5.4 3.5 8.7c0 7-5.6 12.6-12.6 12.6H28.6c-7 0-12.6-5.6-12.6-12.6c0-3.2 1.2-6.3 3.5-8.7L144.6 54.6zM133 43.6L7.8 175.7C2.8 181 0 188.1 0 195.4C0 211.2 12.8 224 28.6 224H291.4c15.8 0 28.6-12.8 28.6-28.6c0-7.3-2.8-14.4-7.8-19.7L187 43.6C180 36.2 170.2 32 160 32s-20 4.2-27 11.6z"]
  };
  var faSpinnerThird = {
    prefix: 'fat',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", "M470.8 380c3.8 2.2 8.7 .9 10.8-3C501 341 512 299.8 512 256C512 117.3 401.7 4.4 264 .1c-4.4-.1-8 3.5-8 7.9s3.6 8 8 8.1c128.8 4.2 232 110 232 239.9c0 40.9-10.2 79.3-28.2 113c-2.1 3.9-.8 8.8 3 11z"]
  };
  var faClock = {
    prefix: 'fat',
    iconName: 'clock',
    icon: [512, 512, [128339, "clock-four"], "f017", "M496 256A240 240 0 1 1 16 256a240 240 0 1 1 480 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM248 104V256c0 2.7 1.3 5.2 3.6 6.7l96 64c3.7 2.5 8.6 1.5 11.1-2.2s1.5-8.6-2.2-11.1L264 251.7V104c0-4.4-3.6-8-8-8s-8 3.6-8 8z"]
  };
  var faMagnifyingGlass = {
    prefix: 'fat',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M400 208A192 192 0 1 0 16 208a192 192 0 1 0 384 0zM349.3 360.6C312.2 395 262.6 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 54.6-21 104.2-55.4 141.3l149 149c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0l-149-149z"]
  };
  var faSearch = faMagnifyingGlass;
  var faChevronDown = {
    prefix: 'fat',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M250.3 397.7c3.1 3.1 8.2 3.1 11.3 0l216-216c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L256 380.7 45.7 170.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l216 216z"]
  };
  var faXmark = {
    prefix: 'fat',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M338.1 413.4c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L203.3 256 349.4 109.9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L192 244.7 45.9 98.6c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L180.7 256 34.6 402.1c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L192 267.3 338.1 413.4z"]
  };
  var faTimes = faXmark;
  var faFilterSlash = {
    prefix: 'fat',
    iconName: 'filter-slash',
    icon: [640, 512, [], "e17d", "M13 1.7C9.5-1 4.5-.4 1.7 3S-.4 11.5 3 14.3l624 496c3.5 2.7 8.5 2.2 11.2-1.3s2.2-8.5-1.3-11.2L13 1.7zM567.8 93.1c5.3-6.7 8.2-15 8.2-23.5C576 48.8 559.2 32 538.4 32H152.5l20.3 16H538.4c11.9 0 21.6 9.7 21.6 21.6c0 4.9-1.7 9.7-4.7 13.5L424.4 246.7l12.6 9.9L567.8 93.1zM368 385.6v68.3c0 5.6-4.5 10.1-10.1 10.1c-2.1 0-4.2-.7-5.9-1.9l-70.1-51c-6.2-4.5-9.9-11.7-9.9-19.4V309.8l-16-12.6v94.5c0 12.8 6.1 24.8 16.5 32.3l70.1 51c4.5 3.2 9.8 5 15.4 5c14.4 0 26.1-11.7 26.1-26.1V398.3l-16-12.6z"]
  };
  var faHammer = {
    prefix: 'fat',
    iconName: 'hammer',
    icon: [576, 512, [128296], "f6e3", "M424.8 226.2l17.5 17.5c6.2 6.2 6.2 16.4 0 22.6l-8 8c-3.1 3.1-3.1 8.2 0 11.3l24 24c3.1 3.1 8.2 3.1 11.3 0l88-88c3.1-3.1 3.1-8.2 0-11.3l-24-24c-3.1-3.1-8.2-3.1-11.3 0l-8 8c-6.2 6.2-16.4 6.2-22.6 0l-15.9-15.9c-3.9-3.9-5.5-9.4-4.3-14.7c5.5-25-2-52.2-21.9-71L405 50.8C381.2 28.4 349.8 16 317.2 16H299.2c-17.9 0-35.6 3.8-52 11L200 48l79.7 43.5c5.1 2.8 8.3 8.2 8.3 14v12.1c0 16.9 6.7 33.1 18.6 45l38.1 38.1c18 18 42.7 24.9 66 21c5.1-.9 10.3 .8 14 4.5zM460.5 81.1c24.2 22.8 33.2 55.7 26.6 86l4.6 4.6L503 183l8-8c9.4-9.4 24.6-9.4 33.9 0l24 24c9.4 9.4 9.4 24.6 0 33.9l-88 88c-9.4 9.4-24.6 9.4-33.9 0l-24-24c-9.4-9.4-9.4-24.6 0-33.9l8-8-11.3-11.3-6.2-6.2c-28.2 4.8-58.2-3.6-80-25.4l-38.1-38.1C280.4 159 272 138.8 272 117.6V105.5L192.3 62c-5.3-2.9-8.6-8.6-8.3-14.7s3.9-11.5 9.5-14l47.2-21C259.1 4.2 279 0 299.2 0h18.1c36.7 0 72 14 98.7 39.1l44.6 42zM27.4 377.1L260.9 182.6c3.1 4.3 6.5 8.4 10.2 12.3L37.7 389.4 27.4 377.1zm0 0l10.2 12.3C23.9 400.9 16 417.8 16 435.7C16 469 43 496 76.3 496c17.9 0 34.9-7.9 46.3-21.7L317.3 240.7c4.1 3.5 8.3 6.8 12.7 9.7L134.9 484.6c-14.5 17.4-36 27.4-58.6 27.4C34.1 512 0 477.8 0 435.7c0-22.6 10.1-44.1 27.4-58.6z"]
  };
  var faSquareXmark = {
    prefix: 'fat',
    iconName: 'square-xmark',
    icon: [448, 512, [10062, "times-square", "xmark-square"], "f2d3", "M64 48C37.5 48 16 69.5 16 96V416c0 26.5 21.5 48 48 48H384c26.5 0 48-21.5 48-48V96c0-26.5-21.5-48-48-48H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm146.3 82.3c3.1-3.1 8.2-3.1 11.3 0L224 244.7l66.3-66.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3L235.3 256l66.3 66.3c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L224 267.3l-66.3 66.3c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L212.7 256l-66.3-66.3c-3.1-3.1-3.1-8.2 0-11.3z"]
  };
  var faCheck = {
    prefix: 'fat',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M437.7 106.3c3.1 3.1 3.1 8.2 0 11.3l-272 272c-3.1 3.1-8.2 3.1-11.3 0l-144-144c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0L160 372.7 426.3 106.3c3.1-3.1 8.2-3.1 11.3 0z"]
  };

  exports.faAngleRight = faAngleRight;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronUp = faChevronUp;
  exports.faCircleArrowLeft = faCircleArrowLeft;
  exports.faCircleArrowRight = faCircleArrowRight;
  exports.faClock = faClock;
  exports.faFilterSlash = faFilterSlash;
  exports.faHammer = faHammer;
  exports.faPaperPlaneTop = faPaperPlaneTop;
  exports.faSearch = faSearch;
  exports.faSortDown = faSortDown;
  exports.faSortUp = faSortUp;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faSquare = faSquare;
  exports.faSquareCheck = faSquareCheck;
  exports.faSquareEllipsis = faSquareEllipsis;
  exports.faSquareXmark = faSquareXmark;
  exports.faTimes = faTimes;
  exports.faUser = faUser;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
