define("ember-popup-component/components/popup/trigger", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
      type="button"
      {{did-insert @saveTriggerRef}}
      {{on 'click' @togglePopup}}
       ...attributes
      >
      {{yield}}
  </button>
  */
  {
    "id": "ilrkp2M3",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[17,1],[4,[38,0],[[30,2]],null],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@saveTriggerRef\",\"@togglePopup\",\"&default\"],false,[\"did-insert\",\"on\",\"yield\"]]",
    "moduleName": "ember-popup-component/components/popup/trigger.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});