define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faSortDown = {
    prefix: 'far',
    iconName: 'sort-down',
    icon: [320, 512, ["sort-desc"], "f0dd", "M160 425.4L70.6 336H249.4L160 425.4zm-22.6 45.3c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H32c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"]
  };
  var faSquareCheck = {
    prefix: 'far',
    iconName: 'square-check',
    icon: [448, 512, [9745, 9989, 61510, "check-square"], "f14a", "M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
  };
  var faChevronUp = {
    prefix: 'far',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", "M239 111c9.4-9.4 24.6-9.4 33.9 0L465 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-175-175L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 111z"]
  };
  var faAngleRight = {
    prefix: 'far',
    iconName: 'angle-right',
    icon: [320, 512, [8250], "f105", "M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"]
  };
  var faUser = {
    prefix: 'far',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faPaperPlaneTop = {
    prefix: 'far',
    iconName: 'paper-plane-top',
    icon: [512, 512, ["paper-plane-alt", "send"], "e20a", "M133.9 232L65.8 95.9 383.4 232H133.9zm0 48H383.4L65.8 416.1l68-136.1zM44.6 34.6C32.3 29.3 17.9 32.3 8.7 42S-2.6 66.3 3.4 78.3L92.2 256 3.4 433.7c-6 12-3.9 26.5 5.3 36.3s23.5 12.7 35.9 7.5l448-192c11.8-5 19.4-16.6 19.4-29.4s-7.6-24.4-19.4-29.4l-448-192z"]
  };
  var faCircleArrowLeft = {
    prefix: 'far',
    iconName: 'circle-arrow-left',
    icon: [512, 512, ["arrow-circle-left"], "f0a8", "M48 256a208 208 0 1 1 416 0A208 208 0 1 1 48 256zm464 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM223 151l-88 88c-9.4 9.4-9.4 24.6 0 33.9l88 88c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47L360 280c13.3 0 24-10.7 24-24s-10.7-24-24-24l-150.1 0 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0z"]
  };
  var faSquare = {
    prefix: 'far',
    iconName: 'square',
    icon: [448, 512, [9632, 9723, 9724, 61590], "f0c8", "M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
  };
  var faCircleArrowRight = {
    prefix: 'far',
    iconName: 'circle-arrow-right',
    icon: [512, 512, ["arrow-circle-right"], "f0a9", "M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM289 361l88-88c9.4-9.4 9.4-24.6 0-33.9l-88-88c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l47 47L152 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l150.1 0-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0z"]
  };
  var faSquareEllipsis = {
    prefix: 'far',
    iconName: 'square-ellipsis',
    icon: [448, 512, [], "e26e", "M48 416c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80c-8.8 0-16 7.2-16 16l0 320zm16 64c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480zM192 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm-64 32a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm160-32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  var faSortUp = {
    prefix: 'far',
    iconName: 'sort-up',
    icon: [320, 512, ["sort-asc"], "f0de", "M160 86.6L70.6 176H249.4L160 86.6zM137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128z"]
  };
  var faSpinnerThird = {
    prefix: 'far',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", "M457 372c11.5 6.6 26.3 2.7 31.8-9.3C503.7 330.2 512 294.1 512 256C512 122.7 410.1 13.2 280 1.1C266.8-.1 256 10.7 256 24s10.8 23.9 24 25.4C383.5 61.2 464 149.2 464 256c0 29.3-6.1 57.3-17 82.6c-5.3 12.2-1.5 26.8 10 33.5z"]
  };
  var faClock = {
    prefix: 'far',
    iconName: 'clock',
    icon: [512, 512, [128339, "clock-four"], "f017", "M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"]
  };
  var faMagnifyingGlass = {
    prefix: 'far',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"]
  };
  var faSearch = faMagnifyingGlass;
  var faChevronDown = {
    prefix: 'far',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"]
  };
  var faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };
  var faTimes = faXmark;
  var faFilterSlash = {
    prefix: 'far',
    iconName: 'filter-slash',
    icon: [640, 512, [], "e17d", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L408.1 294.6 566.6 100c6.1-7.4 9.4-16.7 9.4-26.3c0-23-18.7-41.7-41.7-41.7h-429c-8.6 0-16.5 2.6-23.1 7.1L38.8 5.1zM134.4 80H521L370.3 265 134.4 80zM288 364.4V322.9l-48-37.8v83.2c0 12.2 5.6 23.7 15.1 31.3L347.6 473c5.7 4.5 12.8 7 20.1 7c17.8 0 32.3-14.5 32.3-32.3V411.2l-48-37.8v41.9l-64-50.8z"]
  };
  var faHammer = {
    prefix: 'far',
    iconName: 'hammer',
    icon: [576, 512, [128296], "f6e3", "M432.8 204.9c-9.1-9.1-21.5-14.2-34.4-14.1c-11.3 .1-22.4-4.1-31-12.7L329.3 140c-5.9-5.9-9.3-14-9.3-22.4V105.5c0-17.6-9.6-33.7-25-42.1L273.3 51.5c8.4-2.3 17.1-3.5 25.8-3.5h18.1c24.5 0 48 9.3 65.8 26.1l44.6 42c9.7 9.1 14.3 21.7 13.5 34.1c-.8 13.7 4.3 27.1 14 36.8l37 37L472 244.1l-39.2-39.2zM216.7 75.3l.3 .2 55 30v12.1c0 21.1 8.4 41.4 23.3 56.3l38.1 38.1c18.1 18.1 41.8 26.9 65.4 26.7l39.2 39.2 1 1-16 16c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L569 217c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-8 8-1-1-37-37c1.6-26.1-8-52.7-28.5-72l-44.6-42C389.2 14 353.9 0 317.2 0H299.2C279 0 259.1 4.2 240.7 12.4L219.1 22l-.3 .1-6.2 2.8-19.1 8.5c-5.6 2.5-9.2 7.9-9.5 14s3 11.8 8.3 14.7l18.4 10 6 3.3zm44.2 107.3L27.4 377.1C10.1 391.6 0 413.1 0 435.7C0 477.8 34.1 512 76.3 512c22.6 0 44.1-10.1 58.6-27.4L330 250.4c-6.8-4.5-13.2-9.7-19.2-15.7l-38.1-38.1c-4.3-4.3-8.3-9-11.8-14z"]
  };
  var faSquareXmark = {
    prefix: 'far',
    iconName: 'square-xmark',
    icon: [448, 512, [10062, "times-square", "xmark-square"], "f2d3", "M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm143 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"]
  };
  var faCheck = {
    prefix: 'far',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"]
  };

  exports.faAngleRight = faAngleRight;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronUp = faChevronUp;
  exports.faCircleArrowLeft = faCircleArrowLeft;
  exports.faCircleArrowRight = faCircleArrowRight;
  exports.faClock = faClock;
  exports.faFilterSlash = faFilterSlash;
  exports.faHammer = faHammer;
  exports.faPaperPlaneTop = faPaperPlaneTop;
  exports.faSearch = faSearch;
  exports.faSortDown = faSortDown;
  exports.faSortUp = faSortUp;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faSquare = faSquare;
  exports.faSquareCheck = faSquareCheck;
  exports.faSquareEllipsis = faSquareEllipsis;
  exports.faSquareXmark = faSquareXmark;
  exports.faTimes = faTimes;
  exports.faUser = faUser;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
