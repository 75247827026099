define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faSortDown = {
    prefix: 'fal',
    iconName: 'sort-down',
    icon: [320, 512, ["sort-desc"], "f0dd", "M154.4 445.8c1.4 1.3 3.5 2.2 5.6 2.2s4.2-.8 5.6-2.2L285.8 332.3c1.4-1.3 2.2-3.2 2.2-5.2c0-3.9-3.2-7.1-7.1-7.1H39.1c-3.9 0-7.1 3.2-7.1 7.1c0 2 .8 3.8 2.2 5.2L154.4 445.8zm-22 23.3L12.3 355.6C4.4 348.2 0 337.9 0 327.1C0 305.5 17.5 288 39.1 288H280.9c21.6 0 39.1 17.5 39.1 39.1c0 10.8-4.4 21.1-12.3 28.5L187.6 469.1c-7.4 7-17.3 10.9-27.6 10.9s-20.1-3.9-27.6-10.9z"]
  };
  var faSquareCheck = {
    prefix: 'fal',
    iconName: 'square-check',
    icon: [448, 512, [9745, 9989, 61510, "check-square"], "f14a", "M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM331.3 203.3l-128 128c-6.2 6.2-16.4 6.2-22.6 0l-64-64c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L192 297.4 308.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"]
  };
  var faChevronUp = {
    prefix: 'fal',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", "M244.7 116.7c6.2-6.2 16.4-6.2 22.6 0l192 192c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L256 150.6 75.3 331.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l192-192z"]
  };
  var faAngleRight = {
    prefix: 'fal',
    iconName: 'angle-right',
    icon: [320, 512, [8250], "f105", "M267.3 244.7c6.2 6.2 6.2 16.4 0 22.6l-160 160c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L233.4 256 84.7 107.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l160 160z"]
  };
  var faUser = {
    prefix: 'fal',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faPaperPlaneTop = {
    prefix: 'fal',
    iconName: 'paper-plane-top',
    icon: [512, 512, ["paper-plane-alt", "send"], "e20a", "M3.4 78.3c-6-12-3.9-26.5 5.3-36.3s23.5-12.7 35.9-7.5l448 192c11.8 5 19.4 16.6 19.4 29.4s-7.6 24.4-19.4 29.4l-448 192c-12.3 5.3-26.7 2.3-35.9-7.5s-11.3-24.3-5.3-36.3L92.2 256 3.4 78.3zM120 272L32 448 442.7 272H120zm322.7-32L32 64l88 176H442.7z"]
  };
  var faCircleArrowLeft = {
    prefix: 'fal',
    iconName: 'circle-arrow-left',
    icon: [512, 512, ["arrow-circle-left"], "f0a8", "M32 256a224 224 0 1 1 448 0A224 224 0 1 1 32 256zm480 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM228.7 148.7l-96 96c-6.2 6.2-6.2 16.4 0 22.6l96 96c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L182.6 272 368 272c8.8 0 16-7.2 16-16s-7.2-16-16-16l-185.4 0 68.7-68.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0z"]
  };
  var faSquare = {
    prefix: 'fal',
    iconName: 'square',
    icon: [448, 512, [9632, 9723, 9724, 61590], "f0c8", "M384 64c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
  };
  var faCircleArrowRight = {
    prefix: 'fal',
    iconName: 'circle-arrow-right',
    icon: [512, 512, ["arrow-circle-right"], "f0a9", "M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM283.3 363.3l96-96c6.2-6.2 6.2-16.4 0-22.6l-96-96c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L329.4 240 144 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l185.4 0-68.7 68.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0z"]
  };
  var faSquareEllipsis = {
    prefix: 'fal',
    iconName: 'square-ellipsis',
    icon: [448, 512, [], "e26e", "M32 416c0 17.7 14.3 32 32 32l320 0c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L64 64C46.3 64 32 78.3 32 96l0 320zm32 64c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480zM200 256a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm-72 24a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm168-24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  var faSortUp = {
    prefix: 'fal',
    iconName: 'sort-up',
    icon: [320, 512, ["sort-asc"], "f0de", "M154.4 66.2c1.4-1.3 3.5-2.2 5.6-2.2s4.2 .8 5.6 2.2L285.8 179.7c1.4 1.3 2.2 3.2 2.2 5.2c0 3.9-3.2 7.1-7.1 7.1H39.1c-3.9 0-7.1-3.2-7.1-7.1c0-2 .8-3.8 2.2-5.2L154.4 66.2zm-22-23.3L12.3 156.4C4.4 163.8 0 174.1 0 184.9C0 206.5 17.5 224 39.1 224H280.9c21.6 0 39.1-17.5 39.1-39.1c0-10.8-4.4-21.1-12.3-28.5L187.6 42.9C180.1 35.9 170.2 32 160 32s-20.1 3.9-27.6 10.9z"]
  };
  var faSpinnerThird = {
    prefix: 'fal',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", "M463.9 376c7.6 4.4 17.5 1.8 21.4-6.1c17.1-34.3 26.7-73 26.7-113.9C512 120 405.9 8.8 272 .5c-8.8-.5-16 6.7-16 15.5s7.2 15.9 16 16.6C388.2 40.8 480 137.7 480 256c0 35.1-8.1 68.3-22.5 97.9c-3.9 8-1.3 17.7 6.4 22.1z"]
  };
  var faClock = {
    prefix: 'fal',
    iconName: 'clock',
    icon: [512, 512, [128339, "clock-four"], "f017", "M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM240 112V256c0 5.3 2.7 10.3 7.1 13.3l96 64c7.4 4.9 17.3 2.9 22.2-4.4s2.9-17.3-4.4-22.2L272 247.4V112c0-8.8-7.2-16-16-16s-16 7.2-16 16z"]
  };
  var faMagnifyingGlass = {
    prefix: 'fal',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L507.3 484.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L343.3 366z"]
  };
  var faSearch = faMagnifyingGlass;
  var faChevronDown = {
    prefix: 'fal',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"]
  };
  var faXmark = {
    prefix: 'fal',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"]
  };
  var faTimes = faXmark;
  var faFilterSlash = {
    prefix: 'fal',
    iconName: 'filter-slash',
    icon: [640, 512, [], "e17d", "M25.9 3.4C19-2 8.9-.8 3.4 6.1S-.8 23.1 6.1 28.6l608 480c6.9 5.5 17 4.3 22.5-2.6s4.3-17-2.6-22.5L25.9 3.4zm541 93.3c5.9-7.1 9.1-16 9.1-25.2C576 49.7 558.3 32 536.5 32H139.6l40.5 32H536.5c4.2 0 7.5 3.4 7.5 7.5c0 1.8-.6 3.4-1.7 4.8l-137 165.5 25.1 19.8L566.9 96.7zM352 362.8v83.3c0 1-.8 1.9-1.9 1.9c-.4 0-.8-.1-1.1-.4l-61-47.4V312.3L256 287V400.2c0 9.9 4.6 19.2 12.4 25.3l61 47.4c5.9 4.6 13.3 7.1 20.8 7.1c18.7 0 33.9-15.2 33.9-33.9V388.1l-32-25.3z"]
  };
  var faHammer = {
    prefix: 'fal',
    iconName: 'hammer',
    icon: [576, 512, [128296], "f6e3", "M398.5 206.8c8.6-.1 16.8 3.3 22.9 9.4L472 266.7 514.7 224l-48.3-48.3c-6.5-6.5-9.9-15.4-9.3-24.5c1-17-5.2-34.2-18.5-46.7L394 62.4C373.2 42.9 345.8 32 317.2 32H299.2c-15.7 0-31.2 3.3-45.5 9.7l-17.6 7.8 51.2 27.9C297.6 83 304 93.8 304 105.5v12.1c0 12.6 5 24.7 13.9 33.7l38.1 38.1c11.7 11.7 27.1 17.5 42.4 17.4zm138.8-5.4l3.3 3.3 8-8c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-112 112c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l16-16-3.3-3.3-50.6-50.6c-23.6 .2-47.3-8.7-65.4-26.7l-38.1-38.1C280.4 159 272 138.8 272 117.6V105.5L192.3 62c-5.3-2.9-8.6-8.6-8.3-14.7s3.9-11.5 9.5-14l47.2-21C259.1 4.2 279 0 299.2 0h18.1c36.7 0 72 14 98.7 39.1l44.6 42c20.5 19.3 30.1 45.9 28.5 72l48.3 48.3zM27.4 377.1L260.9 182.6c3.5 4.9 7.5 9.6 11.8 14l9.7 9.7L47.9 401.7c-10.1 8.4-15.9 20.9-15.9 34C32 460.2 51.8 480 76.3 480c13.1 0 25.6-5.8 34-15.9L305.7 229.6l5.1 5.1c6 6 12.4 11.2 19.2 15.7L134.9 484.6c-14.5 17.4-36 27.4-58.6 27.4C34.1 512 0 477.8 0 435.7c0-22.6 10.1-44.1 27.4-58.6z"]
  };
  var faSquareXmark = {
    prefix: 'fal',
    iconName: 'square-xmark',
    icon: [448, 512, [10062, "times-square", "xmark-square"], "f2d3", "M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm148.7 84.7c6.2-6.2 16.4-6.2 22.6 0L224 233.4l52.7-52.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L246.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L224 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L201.4 256l-52.7-52.7c-6.2-6.2-6.2-16.4 0-22.6z"]
  };
  var faCheck = {
    prefix: 'fal',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z"]
  };

  exports.faAngleRight = faAngleRight;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronUp = faChevronUp;
  exports.faCircleArrowLeft = faCircleArrowLeft;
  exports.faCircleArrowRight = faCircleArrowRight;
  exports.faClock = faClock;
  exports.faFilterSlash = faFilterSlash;
  exports.faHammer = faHammer;
  exports.faPaperPlaneTop = faPaperPlaneTop;
  exports.faSearch = faSearch;
  exports.faSortDown = faSortDown;
  exports.faSortUp = faSortUp;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faSquare = faSquare;
  exports.faSquareCheck = faSquareCheck;
  exports.faSquareEllipsis = faSquareEllipsis;
  exports.faSquareXmark = faSquareXmark;
  exports.faTimes = faTimes;
  exports.faUser = faUser;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
