define("ember-popup-component/components/popup/content", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
      class="dropdown"
       ...attributes
      {{did-insert @saveContentRef}}
      {{did-insert @bindEvents}}
      {{will-destroy @unbindEvents}}>
  
      {{yield}}
  </div>
  */
  {
    "id": "Vd9lenwy",
    "block": "[[[11,0],[24,0,\"dropdown\"],[17,1],[4,[38,0],[[30,2]],null],[4,[38,0],[[30,3]],null],[4,[38,1],[[30,4]],null],[12],[1,\"\\n\\n    \"],[18,5,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@saveContentRef\",\"@bindEvents\",\"@unbindEvents\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-popup-component/components/popup/content.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});