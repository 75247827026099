define("ember-popup-component/components/popup", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "ember-popup-component/utils/click-outside-el", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _runloop, _clickOutsideEl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="popup-wrapper {{if this.opened 'popup-wrapper--opened' 'popup-wrapper--closed'}}" ...attributes>
    {{yield (hash
        Trigger=(component this.popupTrigger saveTriggerRef=this.saveTriggerRef togglePopup=this.togglePopup)
        Content=(component this.popupContent saveContentRef=this.saveContentRef bindEvents=this.bindEvents unbindEvents=this.unbindEvents)
        Modal=(component this.modalContent saveContentRef=this.saveContentRef bindEvents=this.bindEvents unbindEvents=this.unbindEvents)
  
        opened=this.opened
        openPopup=this.openPopup
        closePopup=this.closePopup
        togglePopup=this.togglePopup
  
        contentEl=this.contentEl
        triggerEl=this.triggerEl
    )}}
  </div>
  
  */
  {
    "id": "dh5DYTtI",
    "block": "[[[11,0],[16,0,[29,[\"popup-wrapper \",[52,[30,0,[\"opened\"]],\"popup-wrapper--opened\",\"popup-wrapper--closed\"]]]],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"Trigger\",\"Content\",\"Modal\",\"opened\",\"openPopup\",\"closePopup\",\"togglePopup\",\"contentEl\",\"triggerEl\"],[[50,[30,0,[\"popupTrigger\"]],0,null,[[\"saveTriggerRef\",\"togglePopup\"],[[30,0,[\"saveTriggerRef\"]],[30,0,[\"togglePopup\"]]]]],[50,[30,0,[\"popupContent\"]],0,null,[[\"saveContentRef\",\"bindEvents\",\"unbindEvents\"],[[30,0,[\"saveContentRef\"]],[30,0,[\"bindEvents\"]],[30,0,[\"unbindEvents\"]]]]],[50,[30,0,[\"modalContent\"]],0,null,[[\"saveContentRef\",\"bindEvents\",\"unbindEvents\"],[[30,0,[\"saveContentRef\"]],[30,0,[\"bindEvents\"]],[30,0,[\"unbindEvents\"]]]]],[30,0,[\"opened\"]],[30,0,[\"openPopup\"]],[30,0,[\"closePopup\"]],[30,0,[\"togglePopup\"]],[30,0,[\"contentEl\"]],[30,0,[\"triggerEl\"]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-popup-component/components/popup.hbs",
    "isStrictMode": false
  });
  const ESC = 'Escape';
  let PopupComponent = _exports.default = (_class = class PopupComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "opened", _descriptor, this);
      _initializerDefineProperty(this, "triggerEl", _descriptor2, this);
      _initializerDefineProperty(this, "contentEl", _descriptor3, this);
      _defineProperty(this, "popupTrigger", 'popup/trigger');
    }
    get popupContent() {
      return this.opened ? 'popup/content' : '';
    }
    get modalContent() {
      return this.opened ? 'popup/modal' : '';
    }
    openPopup() {
      this.opened = true;
      (0, _runloop.later)(this, function () {
        window.addEventListener('click', this.closeOnClickOutside);
      }, 100);
    }
    closePopup() {
      this.opened = false;
      window.removeEventListener('click', this.closeOnClickOutside);
    }
    togglePopup() {
      if (this.opened) {
        return this.closePopup();
      } else {
        return this.openPopup();
      }
    }
    closeOnClickOutside(e) {
      const {
        target
      } = e;
      if ((0, _clickOutsideEl.default)(target, this.contentEl)) {
        this.closePopup();
      }
    }
    saveTriggerRef(el) {
      this.triggerEl = el;
    }
    saveContentRef(el) {
      this.contentEl = el;
    }
    bindEvents() {
      window.addEventListener('keyup', this.handleKeyPress, true);
    }
    unbindEvents() {
      window.removeEventListener('keyup', this.handleKeyPress, true);
    }
    handleKeyPress(e) {
      const {
        code
      } = e;
      if (code === ESC) {
        e.stopPropagation();
        this.closePopup();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "opened", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "triggerEl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contentEl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openPopup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openPopup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closePopup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closePopup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePopup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePopup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeOnClickOutside", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeOnClickOutside"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTriggerRef", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveTriggerRef"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveContentRef", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveContentRef"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bindEvents", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "bindEvents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unbindEvents", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unbindEvents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyPress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyPress"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PopupComponent);
});