define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "Appointment category": "Terminkategorie",
    "Category": "Kategorie",
    "Comments": "Kommentare",
    "Contacts": "Personen",
    "Current Pipeline": "Aktuelle Pipeline",
    "Details": "Details",
    "Due": "Fällig",
    "Duration": "Dauer",
    "End": "Ende",
    "EnterUserNameMessage": "Bitte geben Sie einen Namen ein. Dieser wird benutzt um Sie bei Nachfragen und Nachrichten identifizieren zu können",
    "Estimated Time": "Geschätzte Zeit",
    "Filter": "Filter",
    "ForDayliteURL": "https://iosxpert.biz/daylite-crm-software/",
    "Keywords": "Schlagworte",
    "Mark as Completed": "Als erledigt markieren",
    "Message": "Nachricht",
    "No Pipeline": "Keine Pipeline",
    "No comments yet": "Keine Kommentare bisher",
    "No linked appointments": "Keine verknüpften Termine",
    "No linked contacts": "Keine verknüpften Kontakte",
    "No linked tasks": "Keine verknüpften Aufgaben",
    "No role": "Keine Rolle",
    "None": "Keine",
    "Overview": "Übersicht",
    "Pipeline": "Pipeline",
    "RequireUserNameMessage": "Ihr Name wird benötigt, um fortzufahren.",
    "Save": "Speichern",
    "Search": "Suche",
    "Shared by": "Geteilt durch",
    "Sort": "Sortieren",
    "Start": "Beginn",
    "Status": "Status",
    "Status_types": {
      "Abandoned": "Verworfen",
      "Cancelled": "Stoniert",
      "Deferred": "Aufgeschoben",
      "Done": "Fertig",
      "In progress": "In Bearbeitung",
      "New": "Neu",
      "Open": "Offen",
      "Pending": "Ausstehend"
    },
    "Tasks": "Aufgaben",
    "Time Agenda": "Zeitplan",
    "Time Summary": "Zeitliche Zusammenfassung",
    "Title": "Titel",
    "Total Time": "Gesamtzeit",
    "Username": "Ihr Name ?",
    "error": {
      "500": {
        "defaultMessage": "Es tut uns leid, aber es scheint, dass hier etwas nicht wie erwartet funktioniert. Eine Störung ist aufgetreten, die es uns unmöglich macht, die angeforderte Seite anzuzeigen. Wir entschuldigen uns für die Unannehmlichkeiten."
      },
      "defaultMessage": "Es könnte der Fall sein, dass das Ablaufdatum erreicht wurde. Oder es wurde ein fehlerhafter Link benutzt. Bitte prüfen Sie den verwendeten Link oder nehmen Sie Kontakt mit dem Ersteller des ioWeblinks auf, um weitere Unterstützung zu erhalten.",
      "noError": "Es tut uns leid, aber die von Ihnen aufgerufene Error-Seite konnte keinen Fehler identifizieren. Es scheint, dass alles in bester Ordnung ist! Möglicherweise haben Sie einen falschen Link eingegeben oder es gab eine Verwechslung."
    },
    "for": "für",
    "iOSXpertURL": "https://iosxpert.biz/",
    "price_banner": "The {product} costs {price, number, ::currency/USD}"
  }], ["en-us", {
    "Appointment category": "Appointment category",
    "Category": "Category",
    "Comments": "Comments",
    "Contacts": "Contacts",
    "Current Pipeline": "Current Pipeline",
    "Details": "Details",
    "Due": "Due",
    "Duration": "Duration",
    "End": "End",
    "EnterUserNameMessage": "Please enter your name. This will be used to identify you in case of inquiries and messages",
    "Estimated Time": "Estimated Time",
    "Filter": "Filter",
    "ForDayliteURL": "https://iosxpert.biz/en/daylite-crm-software/",
    "Keywords": "Keywords",
    "Mark as Completed": "Mark as Completed",
    "Message": "Message",
    "No Pipeline": "No Pipeline",
    "No comments yet": "No comments yet",
    "No linked appointments": "No linked appointments",
    "No linked contacts": "No linked contacts",
    "No linked tasks": "No linked tasks",
    "No role": "No role",
    "None": "None",
    "Overview": "Overview",
    "Pipeline": "Pipeline",
    "RequireUserNameMessage": "Your name is required to proceed",
    "Save": "Save",
    "Search": "Search",
    "Shared by": "Shared by",
    "Sort": "Sort",
    "Start": "Start",
    "Status": "Status",
    "Status_types": {
      "Abandoned": "Abandoned",
      "Cancelled": "Cancelled",
      "Deferred": "Deferred",
      "Done": "Done",
      "In progress": "In progress",
      "New": "New",
      "Open": "Open",
      "Pending": "Pending"
    },
    "Tasks": "Tasks",
    "Time Agenda": "Time Agenda",
    "Time Summary": "Time Summary",
    "Title": "Title",
    "Total Time": "Total Time",
    "Username": "Your Name ?",
    "error": {
      "500": {
        "defaultMessage": "We apologize, but it seems that something here is not functioning as expected. A disruption has occurred, preventing us from displaying the requested page. We apologize for the inconvenience."
      },
      "defaultMessage": "It's possible that the expiration date has been reached, or an incorrect link was used. Please verify the link you've used or get in touch with the creator of the IO web link for further assistance.",
      "noError": "We apologize, but the error page you've accessed couldn't identify any errors. It appears that everything is in order! You might have entered a wrong link or there was a mix-up."
    },
    "for": "for",
    "iOSXpertURL": "https://iosxpert.biz/en/",
    "price_banner": "The {product} costs {price, number, ::currency/USD}"
  }]];
});